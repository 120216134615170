
$BOX_SIZE: 13px;
$FONT_SIZE: 13px;
$LIGHT_GRAY: #fbfbfb;
$MID_GRAY: #dddddd;

$BOX_UNCHECKED: $MID_GRAY;
$BOX_CHECKED: #5fb032;
$BOX_CHECKED_ALTERNATIVE: orangered;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
