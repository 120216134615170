@import '../../index';

.App .HabitsSettings{
  position: absolute;
  float: right;
  width: 280px;
  right: -180px;
  //top: 25px;
  margin-right: 200px;
}

.HabitsSettings {
  padding: 15px;
  background-color: $LIGHT_GRAY;
  min-width: 280px;


  & .buttonRow {
    & .action {
      float: right;
      margin-left: 5px;
    }
  }

  .buttonRow {
    margin-bottom: 15px;
  }

  .addHabit {
    margin-bottom: 10px;
  }

  .HabitsSetting {
    background-color: gray;
  }

  .Switch {
    margin-top: 5px;
    margin-left: 15px;
  }

  section {
    padding: 16px 12px 12px 12px;
    margin-bottom: -1px;
    border: 1px solid $MID_GRAY;
    height: 60px;
    display: block;

    div {
      display: inline-block;
      margin-left: 6px
    }


    input, input:focus {
      margin-right: 12px;
      border: 0 solid;
      border-bottom: 1px solid #6472ff;
      background-color: transparent;
    }

    button {
      float: right;
    }
  }

}
