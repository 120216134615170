@import '../../index';

.Habit {
  display: inline-block;
  vertical-align: top;
  margin-right: 9px;
  //background-color: $LIGHT_GRAY;
  padding: 5px;


  & header {
    font-size: 12px;
    font-weight: bold;
    min-height: 50px;
    width: 80px;
  }

  &Box {
    display: inline-block;
    width: $BOX_SIZE;
    height: $BOX_SIZE;
    cursor: pointer;
    border: 1px solid white;
    background-color: gray;

    &.checked {
      background-color: $BOX_CHECKED;

      &.Migraine {
        background-color: $BOX_CHECKED_ALTERNATIVE;
      }
    }

    &.unchecked {
      background-color: $BOX_UNCHECKED;
      //visibility: hidden;
    }

    &.hidden {
      visibility: hidden;
      margin-top: 2px;
    }
  }

  .newline {
    display: block;
    visibility: hidden;
    ///*margin-bottom: -7px;*/

    &.empty {
      height: 0;
      margin-top: -10px;
    }
  }
}

.colormethis {
  background-color: #f9f9f9;
}
