@import '../../index';


.HabitsList {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 10px;
    //background: $LIGHT_GRAY;
    //overflow-y: scroll;
    max-width: 70vw;
    height: 120%;
    overflow-y: hidden; // hide vertical
    //white-space: nowrap;
    //overflow-x: scroll;
    //overflow-y: hidden;
    //border: 1px solid $MID_GRAY;

    article {
        flex: 0 0 auto;
    }
    margin-bottom: 40px;
}

.HabitsNav {
    font-size: 13px;
    padding: 6px;
    color: white;
    padding-top: 1px;
}

.habitsLayoutLinks {
    padding: 0;
    margin-left: -5px;
    list-style-type: none;
}

.habitsLayoutLinks li {
    background: coral;
    display: inline-block;
    padding: 3px 10px;
    margin-right: 6px;
}

.habitsLayoutLinks li:hover {
    background: #e5734e;
}

.habitsLayoutLinks li:active {
    background: #e5734e;
}

.habitsLayoutLinks li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

li.disabled {
    background: gray;
    pointer-events: none;
}

.settingsToggle {
    vertical-align: center;
    font-weight: bold;
    border: 1px solid $MID_GRAY;
    margin: 10px 0;
    font-size: 14px;
    padding: 10px 10px;
    background-color: $LIGHT_GRAY;
    display: block;
    width: 150px;
    height: 45px;

    & .toggle {
        margin-top: 0;
        right: 6px;
        float:right;
    }
}
