@import '~antd/dist/antd.css';

.App {
    text-align: left;
    margin-left: 4%;
}

.App h1 {
    margin-bottom: 0px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 1.5vmin);
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
